import { functions, db } from '@/config/firebase.config';

export const fetchCatalogs = () => {
  return functions
    .httpsCallable('fetchCatalogs')()
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const searchCatalogTracks = async ({
  query,
  page = 0,
  algoliaIndex = 'rhio_production_db',
  isDemo = false,
  filters,
  ...config
}) => {
  return functions
    .httpsCallable('searchCatalogTracks')({
      query,
      page,
      algoliaIndex,
      isDemo,
      filters,
      ...config,
    })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const fetchAndUpdatePublishersTracksCount = ({ publishers }) => {
  return functions
    .httpsCallable('fetchAndUpdatePublishersTracksCount')({
      publishers,
    })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const fetchTracksDetailsForDownload = ({ objectIDs }) => {
  return functions
    .httpsCallable('fetchTracksDetailsForDownload')({
      objectIDs,
    })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const fetchTrackRecordings = ({ isrcs }) => {
  return functions
    .httpsCallable('fetchTrackRecordings')({
      isrcs,
    })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const fetchSearchAnalytics = async ({
  query,
  algoliaIndex = 'rhio_production_db',
  isDemo = false,
  publishingPartyObjectID,
  ...config
}) => {
  return functions
    .httpsCallable('fetchSearchAnalytics')({
      query,
      algoliaIndex,
      isDemo,
      publishingPartyObjectID,
      ...config,
    })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const fetchAllTracksCount = async () => {
  const doc = await db
    .collection('config')
    .doc('all-tracks-count')
    .get();
  const data = doc.data();
  return {
    allTracksCount: data.count,
  };
};
