<template>
  <div
    :class="$style.detailsCardContainer"
    v-if="showCountryDetails"
    v-show="true"
  >
    <div
      :class="[
        $style.detailsCard,
        fullScreen && $style.fullScreen,
        isComparing && $style.comparing,
      ]"
    >
      <div v-if="fetchCountryDataStatus === API_STATUS.PENDING">
        <span>Fetching details...</span>
      </div>

      <div
        v-else-if="details"
        :class="[$style.content, $style.contentFullScreen]"
      >
        <header :class="[$style.cardHeader, isComparing && $style.comparing]">
          <BaseButton
            :class="$style.headerIconButton"
            @click.prevent.stop="toggleFullScreen"
            v-show="!isComparing"
          >
            <BaseIcon
              :icon="fullScreen ? 'compress-alt' : 'expand-alt'"
              :class="$style.headerIcon"
            />
          </BaseButton>

          <BaseButton
            :class="$style.headerIconButton"
            @click.prevent.stop="
              $emit(isComparing ? 'compare' : 'close'),
                !isComparing && fullScreen && toggleFullScreen()
            "
          >
            <BaseIcon icon="times" :class="$style.headerIcon"
          /></BaseButton>
        </header>
        <main :class="$style.main">
          <div :class="$style.cardHeaderInfo">
            <h2 :class="$style.source">
              {{ details.source }}
            </h2>
            <h3 :class="$style.sourceName" :title="details.full_name">
              <i>{{ details.full_name }}</i>
            </h3>
            <h3 :class="$style.country">{{ details.country }}</h3>
          </div>

          <div>
            <ul :class="$style.detailsList">
              <li :class="$style.detailsRow">
                <span :class="$style.detailsLabel">
                  {{ details.source }} Code:</span
                >
                <span :class="$style.detailsValue">
                  {{ details.workcode }}
                </span>
              </li>
              <template>
                <li :class="$style.detailsRow">
                  <span :class="$style.detailsLabel"> ISWC Code: </span>
                  <span :class="$style.detailsValue">
                    <DisplayIswcs :iswcs="details.iswcs" />
                  </span>
                </li>
              </template>
              <li
                :class="[
                  $style.detailsRow,
                  fullScreen && $style.titleRowFullScreen,
                ]"
              >
                <span :class="$style.detailsLabel"> Title: </span>
                <span
                  :class="[
                    $style.detailsValue,
                    highlightTitle && $style.highlight,
                  ]"
                >
                  {{ details.title }}
                </span>
              </li>
            </ul>
          </div>

          <div
            :class="$style.detailsTableContainer"
            @mousewheel="onMouseWheelScroll"
            ref="cardTableContainer"
          >
            <table :class="$style.detailsTable">
              <thead :class="$style.tableHead">
                <tr>
                  <td :class="$style.detailsTableHeading">role:</td>
                  <td
                    :class="[$style.detailsTableHeading, $style.partyHeading]"
                  >
                    parties:
                  </td>
                  <td :class="$style.detailsTableHeading">ipi:</td>
                  <td :class="$style.detailsTableHeading">affiliation:</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, idx) of getSortedParties(details.items)"
                  :key="`${idx}-${item.id}`"
                  :class="item.highlight && $style.highlight"
                >
                  <td :class="$style.detailsTableCell">{{ item.role }}</td>

                  <td :class="[$style.detailsTableCell, $style.partyCell]">
                    <button
                      type="button"
                      :class="$style.searchPartyNameBtn"
                      @click="$emit('onSearchByPartyName', item)"
                    >
                      {{ item.party }}
                    </button>
                  </td>
                  <td :class="$style.detailsTableCell">{{ item.ipi }}</td>
                  <td :class="$style.detailsTableCell">
                    {{
                      sourcesById[item.society]
                        ? sourcesById[item.society].source
                        : ''
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>
      <div v-else :class="[$style.content, $style.contentFullScreen]">
        <div :class="$style.noDataFoundContainer">
          <p>No data found.</p>
          <BaseButton
            :class="$style.headerIconButton"
            @click.prevent.stop="
              $emit(isComparing ? 'compare' : 'close'),
                !isComparing && fullScreen && toggleFullScreen()
            "
          >
            <BaseIcon icon="times" :class="$style.headerIcon"
          /></BaseButton>
        </div>
      </div>
      <footer :class="[$style.cardFooter]">
        <template v-if="isOriginalCard">
          <div :class="$style.tabsContainer">
            <div v-if="numTabs > 1 && isOriginalCard" :class="$style.tabs">
              <button
                v-for="tab of numTabs"
                :key="tab"
                :class="[
                  $style.tab,
                  tab - 1 === currentTab && $style.activeTab,
                ]"
                @click.stop.prevent="setTab(tab - 1)"
              ></button>
            </div>
          </div>
          <div>
            <button
              v-if="isOriginalCard && !fullScreen && recordingsIsrcs.length"
              :class="$style.compareBtn"
              @click.prevent.stop="onShowRecordingsModal"
              title="Show Recordings"
            >
              <span
                ><BaseIcon
                  icon="compact-disc"
                  :class="[$style.showRecordingsIcon]"
                />
              </span>
            </button>
            <button
              v-if="
                isOriginalCard && !fullScreen && details.registrationCount > 1
              "
              title="Compare Registrations"
              :class="$style.compareBtn"
              @click.prevent.stop="
                $emit('compare'), fullScreen && toggleFullScreen()
              "
            >
              <span
                ><BaseIcon
                  icon="exchange-alt"
                  :class="[
                    $style.compareIcon,
                    isComparing && $style.compareIconActive,
                  ]"
                />
              </span>
            </button>
          </div>
        </template>
        <template v-else>
          <div :class="$style.sourceTagsContainer">
            <button
              v-for="({ country_tag, source }, idx) of countriesTags"
              :key="`${source}-${idx}`"
              :class="[
                $style.sourceTag,
                selectedCompareSource === source && $style.sourceTagActive,
              ]"
              @click.prevent.stop="
                onChangeCompareSource({ country_tag, source })
              "
            >
              {{ source }}
            </button>
          </div>
        </template>
      </footer>
    </div>
    <RecordingsModal
      v-if="details && recordingsIsrcs.length"
      :show.sync="showRecordingsModal"
      :isrcs="recordingsIsrcs"
      :track="details"
    />
  </div>
</template>

<script>
import { API_STATUS } from '@/constants/apiStatus';
import {
  sourceState,
  setSourceDetails,
  setCardTableContainerRef,
  scrollTableContainer,
} from './detailsCompareService';
import RecordingsModal from './RecordingsModal';
import DisplayIswcs from './DisplayIswcs';
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  components: {
    RecordingsModal,
    DisplayIswcs,
  },
  props: {
    showCountryDetails: {
      type: Boolean,
      default: false,
    },
    sources: {
      type: Object,
    },
    fetchCountryDataStatus: {
      type: String,
      validator: (value) => [IDLE, PENDING, SUCCESS, ERROR].includes(value),
    },
    selectedSource: {
      type: String,
      default: '',
    },
    isComparing: {
      type: Boolean,
      default: false,
    },
    isOriginalCard: {
      type: Boolean,
      default: false,
    },
    countriesTags: {
      type: Array,
    },
    countriesData: {
      type: Object,
    },
    selectedCompareCountry: {
      type: String,
    },
    selectedCompareSource: {
      type: String,
    },
    sourcesById: {
      type: Object,
    },
  },
  data() {
    return {
      showModal: false,
      currentTab: 0,
      fullScreen: false,
      differences: [],
      showRecordingsModal: false,
    };
  },
  watch: {
    selectedSource: {
      immediate: true,
      handler(newSource) {
        if (!newSource || !this.sources) return;
        const srcIdx = Object.values(this.sources).findIndex(
          ({ source }) => source === newSource
        );
        if (srcIdx !== -1 && srcIdx !== this.currentTab) {
          this.setTab(srcIdx);
        }
      },
    },
    isComparing: {
      immediate: true,
      handler(isComparing) {
        if (this.isOriginalCard || !isComparing) return;
        const activeCompareSource = this.pickActiveCompareSource(
          this.selectedSource
        );

        this.$emit('onChangeCompareSource', activeCompareSource);
      },
    },
    details: {
      immediate: true,
      handler(newDetails, oldDetails) {
        // This code should only run for the original card.
        // We want to let the second compare card know what source should be used for comparison
        if (!this.isOriginalCard) return;
        if (
          newDetails &&
          newDetails.workcode === sourceState.source?.workcode &&
          newDetails.source === sourceState.source?.source
        )
          return;
        setSourceDetails(newDetails);
      },
    },
  },
  created() {
    this.API_STATUS = API_STATUS;
  },
  mounted() {
    this.setCardTableContainerRef();
  },
  updated() {
    this.setCardTableContainerRef();
  },
  computed: {
    highlightTitle() {
      if (this.isOriginalCard) return false;
      return this.details?.title !== sourceState.source?.title;
    },
    recordingsIsrcs() {
      return (
        this.details?.recordings?.flatMap((recording) => recording.isrcs) || []
      );
    },
    // Return details for the current card
    details() {
      if (!this.sources) return null;
      const data = Object.values(this.sources);
      if (!data.length) return null;

      if (this.currentTab > data.length - 1) {
        return data[0];
      }
      let details = data[this.currentTab];
      // Handle the left card
      if (this.isOriginalCard) {
        if (!this.isComparing) return details;
        try {
          if (!this.countriesData[this.selectedCompareCountry]) return details;
          /**
           * Here we need to compare the items from the original card against the ones from the compared one.
           */
          // Find the source we are comparing against
          const [, comparedSource] = Object.entries(
            this.countriesData[this.selectedCompareCountry]
          ).find(([key]) =>
            key.toLowerCase().includes(this.selectedCompareSource.toLowerCase())
          );
          // Extract parties
          const comparedSourceParties = comparedSource.items.map(
            (item) => item.party
          );
          // Create a new details object with highlighted items.
          // Items that are not present in the other card are highlighted.
          if (!comparedSource) {
            const items = details.items.map((item) => {
              if (!comparedSourceParties.includes(item.party)) {
                return {
                  ...item,
                  highlight: true,
                };
              }
              return item;
            });

            return {
              ...details,
              items,
            };
          }
        } catch (error) {
          console.error(error);
          return details;
        }
      }

      // Handle the right (compare) card
      if (this.selectedCompareSource !== details.source) {
        const matchingDoc = data.find(
          (item) => item.source === this.selectedCompareSource
        );
        if (matchingDoc) {
          details = matchingDoc;
        }
      }

      return {
        ...details,
        items: details.items.map((item) => {
          if (!sourceState.parties.includes(item.party)) {
            return {
              ...item,
              highlight: true,
            };
          }
          return item;
        }),
      };
    },
    numTabs() {
      const tabs = Object.keys(this.sources || {}).length;
      return tabs > 12 ? 12 : tabs;
    },
  },
  methods: {
    getSortedParties(items) {
      return [...items].sort((a, b) => {
        if (a.role < b.role) return -1;
        if (a.role > b.role) return 1;
        return 0;
      });
    },
    onShowRecordingsModal() {
      this.showRecordingsModal = true;
    },
    /**
     * This method sets the ref of the table container outside
     * We need it for scroll sync functionality
     */
    setCardTableContainerRef() {
      const type = this.isOriginalCard ? 'original' : 'compare';
      setCardTableContainerRef(this.$refs.cardTableContainer, type);
    },
    /**
     * Sync scroll position of table containers when comparing
     */
    onMouseWheelScroll() {
      if (!this.isComparing) return;
      const $el = this.$refs.cardTableContainer;
      const position = {
        top: $el.scrollTop,
        left: $el.scrollLeft,
      };
      const type = this.isOriginalCard ? 'original' : 'compare';

      scrollTableContainer(position, type);
    },
    setTab(tab) {
      this.currentTab = tab;
    },
    toggleFullScreen() {
      this.fullScreen = !this.fullScreen;
    },
    pickActiveCompareSource(selectedSource) {
      for (const { source, country_tag, source_id } of this.countriesTags) {
        if (source !== selectedSource) {
          return {
            source,
            country_tag,
            source_id,
          };
        }
      }
    },
    onChangeCompareSource(compareSource) {
      this.$emit('onChangeCompareSource', compareSource);
      if (!this.countriesData[compareSource.country_tag]) {
        this.$emit('fetchCountryDetails', compareSource);
      }
    },
  },
};
</script>

<style lang="scss" module>
$textColorBlue: #45507f;

.noDataFoundContainer {
  display: flex;
  min-width: 15rem;
  justify-content: space-between;
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  &.comparing {
    justify-content: flex-end;
  }
}

.headerIcon {
  font-size: 1.5rem;
  color: #cfd0df;
}

.headerIconButton {
  padding: 0 !important;
  min-width: auto !important;
  @media (max-width: 768px) {
    display: none;
  }
}

.tableHead {
  position: sticky;
  top: 0;
  background: white;
}

.searchPartyNameBtn {
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  font-size: 1.2rem;
}

.source {
  font-family: 'HelveticaNeue';
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.sourceName {
  font-family: 'HelveticaNeue';
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.country {
  font-family: 'HelveticaNeue';
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #bd7eee;
}

.detailsCardContainer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  font-family: 'HelveticaNeue';

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    height: 100%;
  }
}

.detailsCard {
  display: flex;
  height: 45rem;
  flex-direction: column;
  pointer-events: all;
  background-color: #fff;
  padding: 2.5rem 2rem;
  width: 100%;
  position: relative;
  z-index: 10;
  max-width: 540px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  @media (max-width: 768px) {
    height: 100%;
    padding: 1.5rem 1rem;
    max-width: 100%;
  }

  &.comparing {
    max-width: 540px;
  }
}

.fullScreen {
  position: fixed;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  min-height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.main {
  @media (max-width: 768px) {
    max-width: calc(100% - 1.5rem);
    overflow: hidden;
  }
}

.cardHeaderInfo {
  height: 11rem;
}

.content {
  display: flex;
  flex-direction: column;
  height: inherit;
  max-width: 60rem;
  .contentFullScreen {
    max-width: 100%;
  }
}

.detailsTableContainer {
  max-height: 240px;
  position: relative;
  overflow: auto;
  transform: translateX(-0.5rem);
}

.detailsTable {
}

.detailsTableHeading {
  color: $textColorBlue;
}

.detailsTableCell,
.detailsTableHeading {
  font-weight: 500;
  font-family: 'HelveticaNeue';
  min-width: 4rem;
  font-size: 1.25rem;
  letter-spacing: -0.25px;
  padding: 0rem 0.5rem;
}

.fullScreen {
  .detailsTableCell,
  .detailsTableHeading {
    min-width: 10rem;
  }

  .cardFooter {
    width: 100%;
    max-width: 60rem;
    margin-top: 2rem;
  }

  .detailsTableContainer {
    max-height: 250px;
  }

  .content {
    height: initial;
  }
}

.partyHeading,
.partyCell {
  min-width: 28rem !important;
}

.highlight {
  background-color: #fef3c7;
}

.detailsList {
  list-style: none;
  letter-spacing: -0.05px;
}

.fullScreen {
  .detailsList {
    display: flex;
    align-items: flex-start;
    column-gap: 3rem;
    row-gap: 1rem;
    flex-wrap: wrap;
  }
}

.detailsRow {
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-start;
}

.detailsLabel {
  color: $textColorBlue;
  font-weight: 500;
  font-family: 'HelveticaNeue';
  font-size: 1.25rem;
  margin-right: 0.5rem;
  display: inline-block;
  flex-shrink: 0;
}

.detailsValue {
  color: #333;
  font-weight: 500;
  font-family: 'HelveticaNeue';
  font-size: 1.25rem;
}

.titleRowFullScreen {
  flex-basis: 100%;
}

.cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}

.tabsContainer {
  overflow-x: auto;
  max-width: 80%;
}

.tabs {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  @media (max-width: 768px) {
    padding: 2rem;
  }
}

.tab {
  background-color: #d8d8d8;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  border: none;
  flex-shrink: 0;
}

.activeTab {
  background-color: #45507f;
}

.compareBtn {
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
}

.showRecordingsIcon,
.compareIcon {
  font-size: 1.5rem;
  color: #cfd0df;
}

.compareIconActive {
  color: #3f487a;
}

.sourceTagsContainer {
  display: flex;
  overflow-y: auto;
  gap: 1rem;
}

.sourceTag {
  background: transparent;
  border: none;
  font-family: HelveticaNeue;
  font-weight: 500;
  color: #3f487a;
  font-size: 1.2rem;
  cursor: pointer;
}

.sourceTagActive {
  color: #bd7eee;
}
</style>
