<template>
  <div>
    <span v-if="displayShowMoreCodesText">
      <span>{{ iswcs.length }} codes</span
      ><BaseButton
        variant="primary"
        type="button"
        :class="$style.viewAllBtn"
        @click="isIswcsModalVisible = true"
      >
        View all
      </BaseButton>
    </span>
    <span v-else>{{ iswcs.slice(0, 3).join(", ") }}</span>
    <vue-final-modal
      @change="onIswcsModalChange"
      :value="isIswcsModalVisible"
      name="'iswcs-modal'"
    >
      <div :class="$style.iswcsModalContentContainer">
        <div :class="$style.iswcsModalContent">
          <h2>ISWC codes</h2>
          <ul :class="$style.iswcsList">
            <li v-for="(iswc, idx) of iswcs" :key="`${iswc}-${idx}`">
              {{ iswc }}
            </li>
          </ul>
          <div :class="$style.actions">
            <BaseButton
              variant="primary-solid"
              @click="onIswcsModalChange(false)"
              >Close</BaseButton
            >
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
export default {
  props: {
    iswcs: {
      type: Array,
    },
  },
  data() {
    return {
      isIswcsModalVisible: false,
    };
  },
  computed: {
    displayShowMoreCodesText() {
      return this.iswcs?.length > 2;
    },
  },
  methods: {
    onIswcsModalChange(nextState) {
      this.isIswcsModalVisible = nextState;
    },
  },
};
</script>

<style lang="scss" module>
.viewAllBtn {
  padding: 0 !important;
  margin-left: 1rem;
}
.iswcsModalContentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: left;
}
.iswcsModalContent {
  padding: 2rem;
  background: #fff;
  min-width: 22rem;
}
.iswcsList {
  list-style: none;
  padding: 0;
  margin-top: 2rem;
  max-height: 400px;
  overflow-y: auto;
}

.actions {
  margin-top: 2rem;
  text-align: right;
}
</style>