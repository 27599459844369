<template>
  <vue-final-modal
    @change="onRecordingsModalChange"
    :value="show"
    name="recordings-modal"
  >
    <div :class="$style.modalRoot">
      <div :class="$style.modalContent">
        <BaseButton
          :class="$style.headerIconButton"
          @click.prevent="onRecordingsModalChange(false)"
        >
          <BaseIcon icon="times" :class="$style.headerIcon"
        /></BaseButton>
        <div v-if="fetchRecordingsStatusPending">Loading..</div>

        <div v-else-if="recordings.length && track">
          <div>
            <div :class="$style.headerTitle">
              <span :class="$style.recordingsCount"
                >{{ recordings.length }} </span
              ><span>recordings for</span>
              <span :class="$style.trackTitle"> {{ track.title }}</span>
              <span :class="$style.trackIswc">{{
                track.iswcs.join(", ")
              }}</span>
            </div>
          </div>
          <div :class="$style.tableContainer" ref="cardTableContainer">
            <table :class="$style.detailsTable">
              <thead :class="$style.tableHead">
                <tr>
                  <th :class="$style.detailsTableHeading">ISRC</th>
                  <th
                    :class="$style.detailsTableHeading"
                    style="min-width: 20rem"
                  >
                    Artist
                  </th>
                  <th
                    :class="$style.detailsTableHeading"
                    style="min-width: 22rem"
                  >
                    Release
                  </th>
                  <th
                    :class="$style.detailsTableHeading"
                    style="min-width: 22rem"
                  >
                    Label
                  </th>
                  <th
                    :class="$style.detailsTableHeading"
                    style="min-width: 22rem"
                  >
                    Distributor
                  </th>
                  <th :class="$style.detailsTableHeading">Year</th>
                  <th :class="$style.detailsTableHeading">Confidence</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, idx) of recordings"
                  :key="`${idx}-${item.isrc}-${item.year}`"
                  :class="item.highlight && $style.highlight"
                >
                  <td :class="$style.detailsTableCell">{{ item.isrc }}</td>

                  <td
                    :class="[$style.detailsTableCell, $style.partyCell]"
                    :title="item.artist"
                  >
                    {{ item.truncatedArtist }}
                  </td>
                  <td
                    :class="$style.detailsTableCell"
                    :title="item.releaseName"
                  >
                    {{ item.truncatedReleaseName }}
                  </td>
                  <td :class="$style.detailsTableCell" :title="item.label">
                    {{ item.truncatedLabel }}
                  </td>
                  <td
                    :class="$style.detailsTableCell"
                    :title="item.distributor"
                  >
                    {{ item.truncatedDistributor }}
                  </td>
                  <td :class="$style.detailsTableCell">{{ item.year }}</td>
                  <td :class="$style.detailsTableCell">
                    {{ item.confidence }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <BaseButton
            @click.prevent="exportRecordings"
            :class="$style.exportRecordings"
          >
            Export Recordings
          </BaseButton>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>
<script>
import { API_STATUS } from "@/constants/apiStatus.js";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { withAsync, truncate, downloadFile } from "@/helpers";
import { fetchTrackRecordings } from "@/api/catalogApi";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isrcs: {
      type: Array,
    },
    track: {
      type: Object,
    },
  },
  data() {
    return {
      recordings: [],
      fetchRecordingsStatus: IDLE,
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchRecordingsStatus"),
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$vfm.show("recordings-modal");
          if (!this.recordings.length && this.fetchRecordingsStatus === IDLE) {
            this.fetchRecordingsData(this.isrcs);
          }
        } else {
          this.$vfm.hide("recordings-modal");
        }
      },
    },
  },
  methods: {
    exportRecordings() {
      const fileName = `${this.track.title}-${this.track.objectID}`;
      const headers = [
        "isrc",
        "iswc",
        "artist",
        "label",
        "year",
        "releaseName",
        "distributor",
        "confidence",
      ].join(",");
      const preparedData = this.recordings.map((obj) => {
        const {
          isrc,
          artist,
          label,
          year,
          releaseName,
          distributor,
          confidence,
        } = obj;
        return [
          isrc,
          this.track.iswcs.join(" | "),
          artist,
          label,
          year,
          releaseName,
          distributor,
          confidence,
        ]
          .map((item) => `"${item}"`)
          .join(",");
      });

      downloadFile({
        data: [headers, ...preparedData].join("\n"),
        fileName,
        fileType: "text/csv",
      });
    },
    async fetchRecordingsData(isrcs) {
      this.fetchRecordingsStatus = PENDING;
      const { response, error } = await withAsync(() =>
        fetchTrackRecordings({ isrcs })
      );
      if (error) {
        this.fetchRecordingsStatus = ERROR;
        console.error(error);
        return;
      }
      this.recordings = response.recordings.map((item) => {
        item.truncatedLabel = truncate(item.label, 25);
        item.truncatedArtist = truncate(item.artist, 25);
        item.truncatedReleaseName = truncate(item.releaseName, 25);
        item.truncatedDistributor = truncate(item.distributor, 25);
        return item;
      });
      this.fetchRecordingsStatus = SUCCESS;
    },
    onRecordingsModalChange(value) {
      this.$emit("update:show", value);
    },
  },
};
</script>
<style lang="scss" module>
.modalRoot {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .exportRecordings {
    padding: 0;
    margin-top: 2rem;
    position: absolute;
    left: 4rem;
    bottom: 2rem;
  }
}

.modalContent {
  background: white;
  //   min-width: 32rem;
  min-width: min(1065px, 90vw);
  max-width: 1065px;
  height: min(32rem, 85vh);
  padding: 6rem 4rem;
  position: relative;
}

.headerIconButton {
  padding: 0 !important;
  min-width: auto !important;
  position: absolute;
  font-size: 1.6rem;
  top: 4rem;
  right: 4rem;
  font-size: 2rem !important;
  color: $color-grey-light !important;
  &:hover {
    color: $color-primary !important;
  }
}

.tableContainer {
  max-height: 22rem;
  overflow-y: auto;
}

.tableHead {
  position: sticky;
  top: 0;
  background: white;
}
.recordingsCount {
  color: $color-primary;
  font-size: 1.6rem;
  font-weight: bold;
}

.trackTitle {
  font-weight: bold;
  font-size: 2.5rem;
  text-transform: uppercase;
  display: block;
  margin: 0.5rem 0;
}

.trackIswc {
  font-size: 1rem;
  font-weight: normal;
  color: $color-grey-light;
  display: block;
  margin-bottom: 4rem;
}

.headerTitle {
  font-size: 1.6rem;
  font-weight: bold;
}

.detailsTableHeading,
.detailsTableCell {
  padding: 0.25rem 1rem;
  display: table-cell !important;

  &:first-child {
    padding-left: 0rem;
  }

  &:last-child {
    padding-right: 1rem;
  }
}
</style>